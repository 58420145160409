// import { BsBuildingsFill } from "react-icons/bs";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
// import { GiBrain } from "react-icons/gi";
import { SiSoundcharts, } from "react-icons/si";


export const routes = [

  // {
  //   title: "PRODUCTS",
  //   href: "/about-us",
  //   Icon: SiSoundcharts,
  //   hash: false,
  // },

  // {
  //   title: "SOLUTIONS",
  //   href: "/about-us",
  //   Icon: SiSoundcharts,
  //   hash: false,
  // },

  {
    title: "ABOUT US",
    href: "/home#about-us",
    Icon: SiSoundcharts,
    hash: true,
  },
  {
    title: "CONTACT",
    href: "/home#contact",
    Icon: MdOutlineConnectWithoutContact,
    hash: true,
  },
];

