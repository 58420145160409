import { ReactComponent as WorkIcon } from "../../components/asset/pie.svg";
import { ReactComponent as SchoolIcon } from "../../components/asset/aly.svg";


const Desp=()=>{

  return(
    <div>
      <p>We provide the following services both digitally and through legacy consulting:</p>
      <ui>
        <li>Contract Administration Services</li>
        <li>Project Management Services</li>
      </ui>
    </div>


  )
}

let timelineElements = [
    {
      id: 1,
      description:
      "Experience seamless event recording with KONTRACX’s CCMS. From unforeseen site conditions to design modifications, our user-friendly platform ensures that every critical detail is captured accurately, providing you with a complete and transparent record of your project's journey.",
      icon:<WorkIcon />,
      background:"#06D6A0",
      title:"Effortless Construction Event Recording"
    },
    {
      id: 2,
      description:
        "No more delays in communication. KONTRACX eases users in the process of notifying clients about critical events.",
      icon:<SchoolIcon />,
      background:"blue",
      title:"Timely Event Notifications "
    },
    
    {
      id: 3,
      description:
        "Say goodbye to cumbersome paperwork and manual processes. Our intuitive interface ensuring that information flows seamlessly through your construction management workflow. Real-time updates keep all stakeholders in the loop, fostering collaboration and reducing the risk of miscommunication. Optimize your internal workflows. ",
        icon:<SchoolIcon />,
        background:"red",
        title:"Streamlined Internal Processes"
    },
    {
      id: 4,
      description:
        "Get a comprehensive overview of your critical events that give raise to claim with KONTRACX's detailed reporting features. Generate insightful reports that highlight the status of events, their impact on schedule and budget, and any associated risks. Leverage these reports to communicate effectively with stakeholders and make data-driven decisions.",
        icon:<WorkIcon />,
        background:"#f9c74f",
        title:"Detailed Status Reporting"
    },
    {
      id: 5,
      description:<Desp/>,
        icon:<WorkIcon />,
        background:"#f9c74f",
        title:"What We Offer?",
        dot1:"Contract Administration"
    },

    
    
    // {
    //   id: 5,
    //   title: "Hawking College",
    //   location: "Skystead, Craonia",
    //   description:
    //     "",
    //   buttonText: "College Projects",
    //   date: "2007 - 2011",
    //   icon: "school",
    // },
  
  ];

  
  
  export default timelineElements;