import React,{useState} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/header/Header'
import Home from './pages/home/Home'
import NotFound from './pages/notFound/NotFound'

export default function App() {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="layoutBase">
      <BrowserRouter>
        <div className="top">
          <Header isOpen={isOpen} setOpen={setOpen}/>
        </div>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <div className="bottom">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  )
}
