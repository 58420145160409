import React from 'react'
import Box from '../../components/Box'
import Contact from '../../components/Contact'


import Hero from '../../components/Hero'

// import Solutions from '../../components/Solutions'
import Timeline from '../../components/timeline/Timeline'
import UsAbout from '../../components/UsAbout'
import Gallery from '../../components/carousel/Gallery'
import Why from '../../components/Why'

export default function Home() {
    return (
        <div>
            <Hero />
            {/* <Why /> */}
            <div id='product'>
            <Timeline />
            </div>
            {/* <div id='solution'>
            <Solutions />
            </div> */}
            <div id='products'>
            <Gallery/>
            </div>
            
            <div id='about-us'>
                <UsAbout />
            </div>
            {/* <Box/> */}
            
            <div id='contact'>
            <Contact />
            </div>
        </div>
    )
}
