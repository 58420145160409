import React from "react";
import { useClickAway } from "react-use";
import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { routes, } from "./Route";
import { Link } from "react-router-dom";
import LOGO from "../asset/ko-logo.png";
import { HashLink } from "react-router-hash-link";
import { BsBuildingsFill } from "react-icons/bs";
import { GiBrain } from "react-icons/gi";

export default function HeaderMobile({ isOpen, setOpen }) {
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));
  return (
    <div ref={ref} className="lg:hidden fixed w-full bg-white">
      <div className="py-2  w-full flex justify-between items-center px-10 h-[100px] shadow-lg shadow-indigo-300/40">
        <Link to="/">
          <img
            src={LOGO}
            alt="logo"
            className="h-[60px] sm:h-[70px] md:h-[80px]"
          />
        </Link>
        <div
          className="flex relative w-8 h-8 flex-col justify-between items-center lg:hidden z-20"
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <span
            className={`h-1 w-full bg-black rounded-lg transform transition duration-300 ease-in-out ${isOpen ? "rotate-45 translate-y-3.5" : ""
              }`}
          />
          <span
            className={`h-1 w-full rounded-lg transition-all duration-300 ease-in-out ${isOpen ? "w-[0px] bg-white" : "w-full bg-black"
              }`}
          />
          <span
            className={`h-1 w-full bg-black rounded-lg transform transition duration-300 ease-in-out ${isOpen ? "-rotate-45 -translate-y-3.5" : ""
              }`}
          />
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed left-0 shadow-4xl right-0 p-5 pt-0 bg-green-100 border-b border-b-white/20"
          >
            <ul className="grid gap-2">
              
              <motion.li
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.1 + 6 / 10,
                }}
                className="w-full p-[0.08rem] mt-5 rounded-xl bg-gradient-to-tr from-blue-400 via-blue-200 to-blue-300"
              >
                <HashLink
                  smooth
                  onClick={() => setOpen((prev) => !prev)}
                  to="/home#products"
                  className={
                    "flex items-center justify-between w-full p-5 rounded-xl  text-black cursor-pointer"
                  }
                >
                  <span className="flex gap-1  text-lg">
                    PRODUCTS
                  </span>
                  <BsBuildingsFill className="text-xl" />
                </HashLink>
              </motion.li>
              <motion.li
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.1 + 6 / 10,
                }}
                className="w-full p-[0.08rem] my-3 rounded-xl bg-gradient-to-tr from-blue-400 via-blue-200 to-blue-300"
              >
                {/* <HashLink
                  smooth
                  onClick={() => setOpen((prev) => !prev)}
                  to="/home#solution"
                  className={
                    "flex items-center justify-between w-full p-5 rounded-xl  text-white cursor-pointer"
                  }
                >
                  <span className="flex gap-1 text-lg">
                   
                  </span>
                  <GiBrain className="text-xl" />
                </HashLink> */}
              </motion.li>

              {routes.map((route, idx) => {
                const { Icon } = route;

                return (<motion.li
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                    delay: 0.1 + idx / 10,
                  }}
                  key={route.title}
                  className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr from-blue-300 via-blue-200 to-blue-400"
                >
                  {route.hash ? (
                    <HashLink
                      smooth
                      onClick={() => setOpen((prev) => !prev)}
                      to={route.href}
                      className={
                        "flex items-center justify-between w-full p-5 rounded-xl  text-black cursor-pointer"
                      }
                    >
                      <span className="flex gap-1 text-lg">
                        {route.title}
                      </span>
                      <Icon className="text-xl" />
                    </HashLink>
                  ) : (
                    <Link
                      onClick={() => {
                        setOpen((prev) => !prev);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={
                        "flex items-center justify-between w-full p-5 rounded-xl  text-white cursor-pointer"
                      }
                      to={route.href}
                    >
                      <span className="flex gap-1 text-lg">
                        {route.title}
                      </span>
                      <Icon className="text-xl" />
                    </Link>
                  )}
                </motion.li>);
              })}

            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
