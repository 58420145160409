import React from 'react'
import "../../components/timeline/Timeline.css"
// import { ReactComponent as WorkIcon } from "../../components/asset/pie.svg";
// import { ReactComponent as SchoolIcon } from "../../components/asset/aly.svg";
import timelineElements from "./timelineElements";
import { PiBuildingsBold } from "react-icons/pi";
import { HashLink } from 'react-router-hash-link'

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

export default function Timeline() {

    // let workIconStyles = { background: "#06D6A0" };
    // let schoolIconStyles = { background: "#f9c74f" };
    return (
        <div className='time py-10 '>
            <h1 className="title bg-gradient-to-r from-blue-400  to-green-500 bg-clip-text text-transparent">What We Offer</h1>
            <VerticalTimeline
                lineColor='#379237'

            >
                {timelineElements.map((element) => {
                    // let isWorkIcon = element.icon === "work";
                    // let showButton =
                    //     element.buttonText !== undefined &&
                    //     element.buttonText !== null &&
                    //     element.buttonText !== "";

                    return (
                        <VerticalTimelineElement className='vertical-timeline-element--education'
                            key={element.key}
                            contentStyle={{ background: 'rgb(66, 125, 157)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(66, 125, 157)' }}
                            iconStyle={{ background: '#54B435', color: '#fff', fontSize: '10px', }}


                            // iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles} element.background
                            icon={<PiBuildingsBold className='' />}
                        >
                            {/* <h3 className="vertical-timeline-element-title">
                                {element.title}
                            </h3> */}
                            {/* <h5 className="vertical-timeline-element-subtitle">
                                {element.location}
                            </h5> */}
                           <div className='text-2xl font-bold text-white font-serif' >
                           {element.title}
                           </div>

                            <div className=''>
                            <p id="description">{element.description}</p>

                            </div>





                        </VerticalTimelineElement>
                    );
                })}
            </VerticalTimeline>


            <HashLink
                smooth
                to="/home#contact"
            >
                <div className='btn'>

                    <button className='book'>Book a Demo</button>
                </div>
            </HashLink>

        </div>







    )
}


