import React from 'react'


export default function Contact() {
    function Submit(e) {
        const formEle = document.querySelector("form");
        e.preventDefault()
        console.log("submit")
        const formData = new FormData(formEle);
        fetch(
          "https://script.google.com/macros/s/AKfycbwFDvIrLfoYWSZnU5tQaf7PIdjnbQCtjE-XJ6CjlsH7ppT0xDKHBp4ud4oDGC7UjJaP/exec",
          {
            method: "POST",
            body: formData
          }
        ) 
        .then((res) => res.json())
        // alert("Form submissiion was successful. Thankyou. We will get back to you")
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
    return (
        <div className='flex justify-center relative'>
            <img className='h-[800px] w-full' src="https://mozartcultures.com/en/wp-content/uploads/2021/02/2_Blog_UniSpan.jpeg" alt="" />
            <div className='absolute  h-full w-full md:w-[700px] max-w-screen-2xl top-0 flex justify-center items-center'>
                <div className='h-[600px] md:h-[500px] f-full w-full  md:w-[600px]  bg-blue-950 rounded-2xl opacity-90 z-10 '>
                </div>
            </div>
            <div className='absolute  h-full w-full md:w-[700px] max-w-screen-2xl top-0 flex justify-center items-center'>
                <form  className='rounded-2xl opacity-90 z-10' onSubmit={(e)=>Submit(e)}  >
                    <div className='md:h-[500px]  w-full  md:w-[600px] rounded-2xl z-20 '>
                        <div className='h-[80px]  flex items-center justify-center '>
                            <div className='text-3xl font-bold text-white'>
                                <p>Get a Free Demo </p>
                            </div>
                        </div>
                        <div className='h-[100px]  '>
                            <div className='sm:mx-20 mx-10 sm:text-lg font-semibold text-white '>
                                <p className='text-center'>We will help you save time, money and resources and improve productivity at scale - all on one powerful, easy-to-use platform.</p>
                            </div>
                        </div>
                        <div className='md:h-[220px] h-[300px] flex items-center justify-center'>
                            <div className='h-auto sm:px-7 md:px-0 w-auto gap-3  grid grid-flow-row-dense md:grid-cols-2 md:grid-rows-2 '>
                                <div className='h-[60px] flex justify-center items-center  '>
                                    <input className=' h-[50px] md:w-[270px] sm:w-[300px] w-[300px]  rounded-lg px-5  text-black' type="text" placeholder='Name' name="Name" />
                                </div>
                                <div className='h-[60px]  flex justify-center items-center  '>
                                    <input className='h-[50px] sm:w-[300px] w-[300px] md:w-[270px] rounded-lg px-5  text-black' type="text" placeholder='Professional Email' name='Email'/>
                                </div>
                                <div className='h-[60px] flex justify-center items-center'>
                                    <input className='h-[50px] md:w-[270px]  w-[300px] rounded-lg px-5  text-black' type="text" placeholder='Phone Number' name='PhoneNumber' />
                                </div>
                                <div className='h-[60px]  flex justify-center items-center '>
                                    <input className='h-[50px] md:w-[270px] w-[300px] rounded-lg px-5  text-black' type="text" placeholder='Company Name' name='CompanyName' />
                                </div>
                            </div>
                        </div>
                        <div className='h-[100px]  flex justify-center items-center'>
                            <input className='h-[60px] w-[200px] text-white text-lg font-bold bg-blue-700 rounded-xl' type="submit" />
                        </div>
                    </div>
                </form>

                
            </div>
        </div>
    )
}
