import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Table from '../asset/table.png'
import Arrow from '../asset/arrow.png'
import Dash from '../asset/Dashboard.png'
import Mail from '../asset/Mail.png'
import { HashLink } from 'react-router-hash-link'

// Import Swiper styles



// import required modules



export default function App() {
  return (
    <>

      <div className='h-auto w-full mb-20'>

        <div className='h-[80px]  w-full  flex items-center justify-center mt-10'>
          <p className='text-6xl font-bold text-black mt-15'>PRODUCTS</p>
        </div>
        <div className='h-[80px] w-full   flex items-center justify-center '>
          <p className='lg:text-2xl md:text-xl sm:text-lg font-bold text-black ml-8 md:ml-0 '><span className='text-green-500'>Kontracx:</span>  A unique Construction Contracts Administration Software Application</p>
        </div>

        <div className='h-auto w-full  flex justify-center items-center px-20'>
          <div className='h-auto w-[90%]  grid lg:grid-cols-3 gap-16 lg:gap-10 xl:gap-20 '>
            <div className='bg-blue-100 shadow-lg shadow-blue-900/50'>
              <div className='h-[300px] w-full  flex justify-center items-center'>
                <div className='h-[250px] w-[90%]   '>
                  <img src={Table} alt="" className='h-[250px] w-full object-fill ' />
                </div>
              </div>
              <div className='h-[150px] w-full  flex justify-center ml-1'>
                <div className='h-[100px] w-[90%] '>
                  <p className='text-xl font-bold mb-3'>Event Documentation </p>
                  <p>Site Engineer can log into our portal and record all delay events</p>
                </div>
              </div>
              <HashLink
                smooth
                to="/home#contact"
              >
                <div className='h-[50px] bg-blue-600  flex justify-between '>

                  <div>
                    <p className='text-white ml-5 pt-3 font-semibold'>Book A Demo</p>
                  </div>

                  <div className=' h-full w-[15%] flex justify-center items-center'>
                    <button>
                      <img src={Arrow} alt="" className='h-[25px]' />

                    </button>
                  </div>
                </div>
              </HashLink>
            </div>
            <div className='bg-blue-100 shadow-lg shadow-blue-900/50'>
              <div className='h-[300px] w-full  flex justify-center items-center'>
                <div className='h-[250px] w-[90%]   '>
                  <img src={Dash} alt="" className='h-[250px] w-full object-fill ' />
                </div>
              </div>
              <div className='h-[150px] w-full  flex justify-center ml-1'>
                <div className='h-[100px] w-[90%] '>
                  <p className='text-xl font-bold mb-3'>Status Overview </p>
                  <p>All users can view the Dashboard for status of variations and claims  two word heading</p>
                </div>
              </div>
              <HashLink
                smooth
                to="/home#contact"
              >
                <div className='h-[50px] bg-blue-600  flex justify-between '>
                  <div>
                    <p className='text-white ml-5 pt-3 font-semibold'>Book A Demo</p>
                  </div>
                  <div className=' h-full w-[15%] flex justify-center items-center'>
                    <button>
                      <img src={Arrow} alt="" className='h-[25px]' />

                    </button>
                  </div>
                </div>
              </HashLink>
            </div>

            <div className='bg-blue-100 shadow-lg shadow-blue-900/50 '>
              <div className='h-[300px] w-full  flex justify-center items-center'>
                <div className='h-[250px] w-[90%]   '>
                  <img src={Mail} alt="" className='h-[250px] w-full object-fill ' />
                </div>
              </div>
              <div className='h-[150px] w-full  flex justify-center ml-1'>
                <div className='h-[100px] w-[90%] '>
                  <p className='text-xl font-bold mb-3'>Contract Correspondence </p>
                  <p>Our Contracts team will study and prepare contractual correspondences</p>
                </div>
              </div>
              <HashLink
                smooth
                to="/home#contact"
              >
                <div className='h-[50px] bg-blue-600  flex justify-between '>
                  <div>
                    <p className='text-white ml-5 pt-3 font-semibold'>Book A Demo</p>
                  </div>
                  <div className=' h-full w-[15%] flex justify-center items-center'>
                    <button>
                      <img src={Arrow} alt="" className='h-[25px]' />

                    </button>
                  </div>
                </div>
              </HashLink>
            </div>
          </div>

        </div>

      </div>

    </>
  );
}
