import { BsBuildingsFill } from "react-icons/bs";
// import { MdOutlineConnectWithoutContact } from "react-icons/md";
// import { GiBrain } from "react-icons/gi";
import {  SiBookstack } from "react-icons/si";


export const products = [
  {
    title: "Products",
    href: "/home#products",
    Icon: SiBookstack,
    hash: true,
  },
  // {
  //   title: "Solutions",
  //   href: "/solution",
  //   Icon: BsBuildingsFill,
  //   hash: false,
  // },
  
];

