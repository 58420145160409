import React from "react";
import { routes, } from "./Route";
import { products } from "./Products";
import LOGO from "../asset/ko-logo.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
export default function HeaderDesktop() {
  return (
    <ul className="relative hidden  lg:flex lg:items-center gap-5 text-sm h-[100px] py-2 px-20  flex justify-between items-center">
      <div className="flex relative z-20">
      </div>
      <div className="absolute w-[100%] h-full left-0 shadow-lg  pl-24 xl:px-40  shadow-indigo-300/40 ">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <div className=" h-full w-[600px] flex   justify-between">
            <div className="h-full w-[250px] flex justify-center items-center">
            <img src={LOGO} alt="logo" className="h-[50px]" />
            </div>

            <div className="h-full w-[300px] flex gap-10  items-center">
            {products.map((product) => {
          const { href, title, hash } = product;
          return (
            <li>
              {hash ? (
                <HashLink
                  smooth
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-lg font-bold"
                >
                  {title}
                </HashLink>
              ) : (
                <Link
                  key={href}
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-lg font-bold"
                >
                  {title}
                </Link>
              )}
            </li>
          );
        })}
            </div>
          
          </div>
         
        </Link>
      </div>

      {/* <div className="h-[50px] w-[300px] bg-gray-400 relative z-20 flex">
      {routes.map((route) => {
          const { href, title, hash } = route;
          return (
            <li>
              {hash ? (
                <HashLink
                  smooth
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-base font-bold"
                >
                  {title}
                </HashLink>
              ) : (
                <Link
                  key={href}
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-base font-bold"
                >
                  {title}
                </Link>
              )}
            </li>
          );
        })}
      </div> */}

      <div className="flex gap-10 relative z-20 xl:mr-28 mr-20">
        {routes.map((route) => {
          const { href, title, hash } = route;
          return (
            <li>
              {hash ? (
                <HashLink
                  smooth
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-lg font-bold"
                >
                  {title}
                </HashLink>
              ) : (
                <Link
                  key={href}
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-lg font-bold"
                >
                  {title}
                </Link>
              )}
            </li>
          );
        })}
      </div>
    </ul>
  );
}
