import React from 'react'
import { HashLink } from 'react-router-hash-link'

export default function Hero() {
  
  return (
    <div className='h-full w-full flex justify-center items-center py-20 '>
      <div className='h-full w-[80%] max-w-screen-2xl mt-[80px] lg:mt-0'>
        <div className='h-auto w-full font-bold xl:text-4xl text-2xl lg:text-3xl md:text-3xl sm:text-3xl '>
          {/* <p>Transforming Construction</p>
          <p className='mt-1'>Contracts Management</p> */}
          <p className='mt-2 text-blue-700'>WELCOME TO KONTRACX - <span className='text-black    '>YOUR ULTIMATE CONSTRUCTION CONTRACT ADMINISTRATION SOLUTION
</span></p>
        </div>
        <div className='h-[30%] w-full  justify-center items-center my-10 '>
          <p className=' lg:text-xl mb-5'>Are you tired of grappling with the complexities of construction contract administration, especially when it comes to managing events that give rise to claims? </p>
          <p className='lg:text-xl mb-5'>Look no further! <span className='text-blue-700'>KONTRACX</span>  is your comprehensive solution, designed to streamline the construction contract 
                             administration processes.</p>
          <p className=' lg:text-xl '>
            Revolutionise your approach to Construction Contracts Administration - record every event at site,
            ensure timely notifications and track the status of events from inception to close-out.
          </p>
        
        </div>
        <div className='h-auto w-full '>
          <HashLink
          smooth
          to="/home#contact"
          >
          <button className='  bg-blue-950 px-10 py-3 rounded-md border-none text-white text-lg font-semibold'>Book a Demo</button>
          </HashLink>
          
        </div>
      </div>
    </div>
  )
}
