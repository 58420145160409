import React from 'react'

export default function UsAbout() {
  return (
    <div className='h-auto md:h-[900px] w-full bg-gradient-to-r from-black to-blue-900 py-20 flex justify-center items-center '>

      <div className='h-auto  w-[90%] flex justify-center items-center max-w-screen-2xl '>
        <div>
          <p className='text-white font-bold text-6xl text-center py-10'>ABOUT US</p>
          <div className='flex justify-center item-center  mb-20 '>
            <div className='h-auto bg-white w-[95%]   '>
              <div className='h-auto grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-0 sm:gap-5 md:gap-0 '>
                <div className=' h-[350px]'>
                  <img className='h-full w-full' src="https://us.123rf.com/450wm/skynetgame/skynetgame1711/skynetgame171100028/89615873-modern-wood-office-table-with-laptop-smartphone-notebook-and-pen-office-desk-table-with-office.jpg?ver=6" alt="" />
                </div>
                <div className=' h-[350px] flex justify-center items-center'>


                  <div>
                    <div className='h-[50px]  flex justify-center items-center'>
                      <p className='xl:text-3xl lg:text-2xl font-bold'>Who Are We?</p>
                    </div>
                    <div className=' text-center lg:text-base sm:text-sm'>
                      <p className='px-2'>We are professionals who have worked in the contracts domain of different construction contracting companies.
                        We have accumulated experience of 40+ years in contracts administration, claims, variations, extension of time and associated costs, project cost controls, drafting techno-commercial correspondences, contractual correspondences, dispute resolution and arbitration.</p>

                    </div>
                  </div>

                </div>

                {/* </div> */}
                {/* <div className='grid  grid-cols-3 '> */}
                <div className=' h-[350px] flex justify-center items-center'>
                  <div className=''>
                    <div className='h-[50px]  flex justify-center items-center'>
                      <p className='xl:text-3xl lg:text-2xl font-bold'>What did we do about it?</p>
                    </div>
                    <div className=' text-center lg:text-base sm:text-sm'>
                      <p className='px-5'>We decided to use our experiences and lessons learnt to develop effective contract administrative procedures using proprietary software applications to effectively manage construction contracts for Employers/Contractors. This is the genesis of our very own custom built ‘KONTRACX’ software application for construction contracts management.
                      </p>

                    </div>
                  </div>

                </div>

                <div className=' h-[350px]'>
                  <img className='h-full w-full' src="https://images.unsplash.com/photo-1573165662973-4ab3cf3d3508?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d29tZW4lMjBpbiUyMHRlY2h8ZW58MHx8MHx8fDA%3D" alt="" />
                </div>
                {/* <div className=' h-[350px]' >
                <img className='h-full w-full' src="https://wizixtech.com/wp-content/uploads/2021/10/sergey-zolkin-_UeY8aTI6d0-unsplash.jpg" alt="" />

              </div> */}

                {/* <div className='h-[350px] flex justify-center items-center' >
                <div className=''>
                  <div className='h-[50px]  flex justify-center items-center'>
                    <p className='xl:text-3xl lg:text-2xl font-bold'>What we Offer?</p>
                  </div>
                  <div className=' text-center lg:text-base md:text-sm'>
                    <p className='px-4'>We provide the following services both digitally and through legacy consulting:
                    </p>
                    <p className='mt-3'>
                      <li>Contract Management Services</li>
                      <li>Project Management Services</li>
                    </p>
                  </div>
                </div>

              </div> */}
              </div>


            </div>
          </div>

        </div>



      </div>


    </div>
  )
}
