import React from 'react'

export default function Footer() {
  return (
    <div className="w-full flex flex-col justify-center items-center mt-10 py-10 ">
      <div className="w-5/6 max-w-screen-2xl flex lg:flex-row lg:justify-between flex-col justify-center">
        <div className="text-center lg:text-start py-1">
          <p className="font-bold">KONTRACX</p>
          <p className="font-bold">Muscat, Sultanate of Oman</p>
        </div>
        <div className="text-center  py-1">
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
        </div>
        <div className="text-center lg:text-end  py-1">
          <p>admin@kontracx.com</p>
          <p>+(968) 7932 3579</p>
        </div>
      </div>
      <div className="w-5/6 max-w-screen-2xl bg-black h-[1px] my-2"></div>
      <div className="w-5/6 max-w-screen-2xl flex lg:flex-row lg:justify-between flex-col justify-center">
        <div className="text-center lg:text-start py-1">
          <p>&#169; 2023 : Kontracx All Rights Reserved</p>
        </div>
        <div className="text-center lg:text-end py-1">
          <a href="https://pennonn.com/">Powered by : Pennonn Technologies</a>
        </div>
      </div>
    </div>

  )
}
