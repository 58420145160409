import React from "react";
import { routes } from "./Route";
import { products } from "./Products";
import LOGO from "../asset/ko-logo.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
export default function ScrollDesktop() {
  return (
    <ul className="relative hidden lg:flex lg:items-center gap-5 text-sm bg-white h-[80px] py-2 px-20 pl-24 xl:px-40 justify-between items-center">
      <div className="flex  ">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >

          <div className=" h-full w-[600px] flex justify-between">
            <div className="h-full w-[250px]  flex justify-center items-center">
              <img src={LOGO} alt="logo" className="h-[60px]" />
            </div>

            <div className="h-full w-[300px] flex gap-10  items-center">
              {products.map((product) => {
                const { href, title, hash } = product;
                return (
                  <li>
                    {hash ? (
                      <HashLink
                        smooth
                        to={href}
                        className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-lg font-bold"
                      >
                        {title}
                      </HashLink>
                    ) : (
                      <Link
                        key={href}
                        to={href}
                        className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-lg font-bold"
                      >
                        {title}
                      </Link>
                    )}
                  </li>
                );
              })}
            </div>

          </div>
         
        </Link>
      </div>

      <div className="flex gap-8  mr-5">
        {routes.map((route) => {
          const { href, title, hash } = route;
          return (
            <li>
              {hash ? (
                <HashLink
                  smooth
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-base font-bold"
                >
                  {title}
                </HashLink>
              ) : (
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  key={href}
                  to={href}
                  className="flex items-center gap-1 hover:text-neutral-400 transition-all text-black text-base font-bold"
                >
                  {title}
                </Link>
              )}
            </li>
          );
        })}
      </div>
    </ul>
  );
}
